
// Init on refresh
window.onload = function () {
    const currentPage = window.location.pathname;

    if (currentPage.includes('index.html') || currentPage === '/') {
        // Clear session storage
        sessionStorage.clear();

        // Reset form values and checkboxes
        document.getElementById("personal-id").value = "";
        document.getElementById("version-2").checked = false;
    } else if (currentPage.includes('generated-image.html')) {
        injectGeneratedImage();
    }
};

document.getElementById("personal-id").addEventListener("invalid", function (e) {

    swedishValidity(this)
})

document.getElementById("id-form").addEventListener("submit", async function (e) {
    
    e.preventDefault()  

    await generateImage()   
})

document.getElementById("submit-btn").addEventListener("click", async function (e) {

    document.getElementById("personal-id").reportValidity()
    e.preventDefault()  

    await generateImage()   
})

function swedishValidity(personalIdInput) {

    // Custom validation logic in Swedish
    if (personalIdInput.validity.valueMissing) {
        personalIdInput.setCustomValidity("Det här fältet är obligatoriskt.");
    } else if (personalIdInput.validity.tooShort) {
        personalIdInput.setCustomValidity("Personnumret måste vara minst 12 tecken långt.");
    } else if (personalIdInput.validity.tooLong) {
        personalIdInput.setCustomValidity("Personnumret får inte vara längre än 13 tecken.");
    } else {
        personalIdInput.setCustomValidity("");
    }
}


// Creates image on button click
async function generateImage() {

    if (!document.getElementById("personal-id").validity.valid)
        return 
    
    let inputString = document.getElementById("personal-id").value
    
    let isValid = isValidInput(inputString)

    if (isValid) {
        inputString = inputString.split("-").filter(Boolean).join("")
    }

    if (inputString.length != 12 || !isValid) {
        return;
    }

    let isChecked = document.getElementById("version-2").checked
    
    await callServer(inputString, isChecked)
}

// Check that the input is a valid personal-ID for this application
// Used both server-side and client-side
function isValidInput(inputString) {

    if (inputString.length == 13 && inputString[8] != "-")
        return false

    var year = inputString.substring(0, 4)
    var month = inputString.substring(4, 6)
    var day = inputString.substring(6, 8)

    if (year.substring(0, 2) != "20" && year.substring(0, 2) != "19")
        return false

    if (isNaN(new Date(year + "/" + month + "/" + day)))
        return false

    return true
}

module.exports = { isValidInput }



// Uses the validated string to call the server
async function callServer(inputString, isChecked) {
    const url = `https://jennysfunktion.netlify.app/.netlify/functions/generate-image`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                inputString: inputString,
                gradientTrue: isChecked
            })
        });

        if (response.ok) {
            const result = await response.json();

            if (result.imageURL) {
                sessionStorage.setItem('generatedImageURL', result.imageURL);
                window.location.href = `generated-image.html`;
            } else {
                console.log("No image generated or error occurred.");
            }
        } else {
            console.error("Failed to fetch the image URL:", response.statusText);
        }
    } catch (error) {
        console.error("Error fetching the image URL:", error);
    }
}

function injectGeneratedImage() {
    
    const imageURL = sessionStorage.getItem('generatedImageURL');

    if (imageURL) {
        document.getElementById('generated-image').src = imageURL;
    } 
    else {
        window.location.href = 'index.html';
    }
}

 